export const SEX_ITEMS = [
  { key: "ユニセックス", value: "unisex" },
  // { key: "メンズ", value: "mens" },
  { key: "レディース", value: "ladies" },
] as const

export const FABRIC_ITEMS = [
  { key: "メッシュ", value: "mesh" },
  { key: "ドライ", value: "dry" },
  { key: "ガーゼ", value: "gauze" },
  { key: "スウェット", value: "sweat" },
  { key: "裏起毛スウェット", value: "lining-sweat" },
  { key: "パイル", value: "pile" },
  { key: "プレミアムパイル", value: "premium-pile" },
  { key: "サテン", value: "satin" },
  { key: "ベロア", value: "velor" },
  { key: "ソフトニット", value: "soft-knit" },
] as const

export const SEASON_ITEMS = [
  { key: "春~夏", value: "ss" },
  { key: "秋~冬", value: "fw" },
] as const

export const WEAR_CATEGORY_ITEMS = [
  { key: "上下セット", value: "set" },
  { key: "トップス", value: "tops" },
  { key: "ボトムス", value: "bottoms" },
  { key: "アイテム", value: "item" },
] as const

export const SIZE_ITEMS = [
  { key: "FREE", value: "FREE" },
  { key: "XS", value: "XS" },
  { key: "S", value: "S" },
  { key: "M", value: "M" },
  { key: "L", value: "L" },
  { key: "XL", value: "XL" },
  { key: "その他", value: "その他" },
] as const

export const MATERIAL_ITEMS = [
  { key: "Tシャツ（長袖）・長ズボン", value: "short-t-shirt" },
  { key: "Tシャツ（半袖）・半ズボン", value: "long-t-shirt" },
  { key: "スウェット", value: "sweat" },
  { key: "前開き", value: "front-open" },
] as const

export const SEX_VALUES = SEX_ITEMS.map((item) => item.value)
export const FABRIC_VALUES = FABRIC_ITEMS.map((item) => item.value)
export const SEASON_VALUES = SEASON_ITEMS.map((item) => item.value)
export const WEAR_CATEGORY_VALUES = WEAR_CATEGORY_ITEMS.map((item) => item.value)
export const SIZE_VALUES = SIZE_ITEMS.map((item) => item.value)

export type SexValue = (typeof SEX_ITEMS)[number]["value"]
export type SizeValue = (typeof SIZE_ITEMS)[number]["value"]
export type WearCategoryValue = (typeof WEAR_CATEGORY_ITEMS)[number]["value"]
export type SeasonValue = (typeof SEASON_ITEMS)[number]["value"]
export type FabricValue = (typeof FABRIC_ITEMS)[number]["value"]

/**
 * 商品検索のソート順
 */
export const ProductAllSearchInput_Sort = {
  HighPrice: "high_price",
  LowPrice: "low_price",
  Popularity: "popularity",
  Recommendation: "recommendation",
} as const
/**
 * 商品検索のソート順
 */
export type ProductAllSearchInput_Sort = (typeof ProductAllSearchInput_Sort)[keyof typeof ProductAllSearchInput_Sort]
/**
 * 画面用のソート順
 */
export const ProductAllSearchInput_SortItems = {
  [ProductAllSearchInput_Sort.Recommendation]: "おすすめ順",
  [ProductAllSearchInput_Sort.Popularity]: "人気順",
  [ProductAllSearchInput_Sort.LowPrice]: "価格が安い順",
  [ProductAllSearchInput_Sort.HighPrice]: "価格が高い順",
} as const

export const SELECT_GIFT_INIT_DATA = {
  sku_id: "",
  product_id: "",
  product_name: "",
  size: "",
  price: 0,
  sku_name: "",
  sku_code: "",
  img: "",
  message: "",
  message_sku_code: "",
  message_img: "",
  message_price: 0,
  message_sku_id: "",
  card: "",
  card_sku_code: "",
  card_img: "",
  card_price: 0,
  card_sku_id: "",
} as const
/**
 * 返品交換期間の設定値
 */
export const IS_RETURN_OR_EXCHANGE_PERIOD = {
  possible: 14,
  impossible: 0,
  trial: 14,
  trial30days: 30,
  trial120days: 120,
} as const
